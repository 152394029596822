.portal-button {
    position: relative;
    overflow: hidden;
    transition: transform 0.2s;
}

.portal-button:hover {
    transform: scale(1.05);
}

.portal-button.animating {
    pointer-events: none;
}

.lightning-pulse {
    animation: lightningPulse 0.15s infinite;
}

@keyframes lightningPulse {
    0% {
        box-shadow: 0 0 15px #4682B4,
                   0 0 30px #4682B4,
                   0 0 45px #4682B4;
        transform: scale(1);
    }
    50% {
        box-shadow: 0 0 40px #4682B4,
                   0 0 80px #4682B4,
                   0 0 120px #4682B4;
        transform: scale(1.1);
    }
    100% {
        box-shadow: 0 0 15px #4682B4,
                   0 0 30px #4682B4,
                   0 0 45px #4682B4;
        transform: scale(1);
    }
}

.explosion {
    animation: explode 2.5s forwards;
}

@keyframes explode {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

#tsparticles {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.glowing-text {
    text-shadow: 0 0 10px #4682B4,
                 0 0 20px #4682B4,
                 0 0 30px #4682B4;
    animation: pulse 2s infinite;
}

.glowing-text-subtle {
    text-shadow: 0 0 5px #4682B4,
                 0 0 10px #4682B4;
}

.cyberpunk-text {
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-family: 'Arial', sans-serif;
    position: relative;
}

.cyberpunk-text::before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 2px 0 #ff00ff;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    animation: noise-anim 3s infinite linear alternate-reverse;
}

.portal-button {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    position: relative;
    overflow: hidden;
}

.portal-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(45deg, #4682B4, #00BFFF, #4682B4);
    background-size: 400%;
    animation: glowing 20s linear infinite;
    filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s;
}

.portal-button:hover::before {
    opacity: 1;
}

@keyframes pulse {
    0% {
        text-shadow: 0 0 10px #4682B4,
                     0 0 20px #4682B4,
                     0 0 30px #4682B4;
    }
    50% {
        text-shadow: 0 0 20px #4682B4,
                     0 0 30px #4682B4,
                     0 0 40px #4682B4;
    }
    100% {
        text-shadow: 0 0 10px #4682B4,
                     0 0 20px #4682B4,
                     0 0 30px #4682B4;
    }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@keyframes noise-anim {
    0% {
        clip-path: inset(40% 0 61% 0);
    }
    20% {
        clip-path: inset(92% 0 1% 0);
    }
    40% {
        clip-path: inset(43% 0 1% 0);
    }
    60% {
        clip-path: inset(25% 0 58% 0);
    }
    80% {
        clip-path: inset(54% 0 7% 0);
    }
    100% {
        clip-path: inset(58% 0 43% 0);
    }
} 