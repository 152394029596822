/* App.css */
@keyframes fillButton {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.fill-animation {
  position: relative;
  background-color: black; /* Updated background color to bg-purple-500 */

}

.fill-animation::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: MediumAquaMarine;
  z-index: 0;
  animation: fillButton 15s linear forwards;
}

.fill-animation .button-text {
  position: relative;
  z-index: 1;
}

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.custom-video-player::-webkit-media-controls-fullscreen-button,
.custom-video-player::-webkit-media-controls-download-button,
.custom-video-player::-webkit-media-controls-overflow-button,
.custom-video-player::-webkit-media-controls-volume-slider,

.custom-video-player::-webkit-media-controls-enclosure {
  overflow: hidden !important;
}

.custom-video-player::-webkit-media-controls-panel {
  width: calc(100% + 30px) !important;
}


