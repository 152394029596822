@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css or global.css */

.gradient-text {
  background: linear-gradient(to right, #6ee7b7, #3b82f6); /* Example gradient from teal to blue */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradient-text-featured {
  background: linear-gradient(to right, #8a2387, #e94057, #f27121); /* Example vibrant gradient */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.gradient-text-how-it-works {
  background: linear-gradient(to right, #00c6ff, #0072ff); /* Adjusted for starker contrast */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@layer utilities {
  .glow {
    @apply shadow-lg ring-2 ring-offset-2 ring-blue-400 ring-opacity-75;
    animation: glow-animation 1.5s infinite ease-in-out;
  }
  @keyframes glow-animation {
    0%, 100% { box-shadow: 0 0 15px rgba(59, 130, 246, 0.75); }
    50% { box-shadow: 0 0 30px rgba(59, 130, 246, 0.75); }
  }
}

@keyframes pulse-restart {
  0%, 100% {
    background-size: 200% 100%;
    background-position: left center;
  }
  50% {
    background-size: 200% 100%;
    background-position: right center;
  }
}

.progress-pulse-restart {
  background-image: linear-gradient(to right, #60a5fa 0%, #3b82f6 50%, #60a5fa 100%);
  background-size: 200% 100%;
  animation: pulse-restart 2s infinite linear;
}

.clip-item {
  min-width: 75px;
  flex-shrink: 0;
}

.track {
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 600px) {
  .max-w-4xl {
    max-width: 90vw;
    padding: 0 5vw;
  }

  .text-2xl {
    font-size: 1.5rem; /* Adjust font size */
  }

  .grid-cols-3 {
    grid-template-columns: repeat(2, 1fr); /* Change grid layout for smaller screens */
  }
}

.upload-container {
  width: 100%; /* Adjusted for smaller screens */
  padding-bottom: 20px; /* Additional padding at the bottom */
}

/* Media query for tablets and desktops */
@media (min-width: 768px) {
  .upload-container {
    width: 800px; /* Fixed width on larger screens */
    padding-bottom: 0px;
  }
}
