/* Slide down animation for map and timeline */
@keyframes slide-down {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.animate-slide-down {
    animation: slide-down 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Slide left animation for actual year marker */
@keyframes slide-left {
    from {
        transform: translateX(100%) translateY(-50%);
        opacity: 0;
    }
    to {
        transform: translateX(0) translateY(-50%);
        opacity: 1;
    }
}

.animate-slide-left {
    animation: slide-left 0.3s ease-out forwards;
}

/* Remove the range input styles since we're not using them anymore */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-moz-range-track,
input[type="range"]::-webkit-slider-thumb,
input[type="range"]::-moz-range-thumb,
input[type="range"]:disabled::-webkit-slider-thumb,
input[type="range"]:disabled::-moz-range-thumb {
    display: none;
}

/* Add this new style for the arrow buttons */
.portal-nav-button {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

/* Add these styles to your existing TimePortal.css */
.portal-heading {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-family: 'Arial', sans-serif;
    text-shadow: 0 0 5px #4682B4,
                 0 0 10px #4682B4;
    position: relative;
}

.portal-heading::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(45deg, #4682B4, #00BFFF, #4682B4);
    background-size: 400%;
    filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s;
}

/* Update the disabled state styling in your existing TimePortal.css */
input[type="button"]:disabled,
button:disabled,
.portal-nav-button:disabled {
    cursor: not-allowed;
    transform: none;
    border-color: #64748b !important;
    color: #64748b !important;
    text-shadow: none;
    background-color: transparent !important;
}

.portal-nav-button:disabled:hover {
    transform: none;
    background-color: transparent !important;
    box-shadow: none;
}

.portal-nav-button:disabled::before {
    display: none;
}

/* Add these new styles */
.score-display {
    background: transparent !important;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    text-shadow: 0 0 5px #22c55e,
                 0 0 10px #22c55e;
    transition: all 0.3s ease;
    color: #22c55e !important;
}

.score-display:hover {
    text-shadow: 0 0 10px #22c55e,
                 0 0 20px #22c55e;
}

.round-text {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    text-shadow: 0 0 5px #4682B4,
                 0 0 10px #4682B4;
}

.portal-nav-button {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    position: relative;
    overflow: hidden;
}

.portal-nav-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    background: linear-gradient(45deg, #4682B4, #00BFFF, #4682B4);
    background-size: 400%;
    animation: glowing 20s linear infinite;
    filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s;
}

.portal-nav-button:hover::before {
    opacity: 1;
}

.actual-info {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    text-shadow: 0 0 5px #4682B4,
                 0 0 10px #4682B4;
    transition: all 0.3s ease;
    color: #4682B4 !important;
}

.actual-info:hover {
    text-shadow: 0 0 10px #4682B4,
                 0 0 20px #4682B4;
}

/* Add these styles to your existing CSS file */
.guess-indicator {
    pointer-events: none;
    z-index: 10;
}

/* Optional: Add visual feedback for the draggable area */
.timeline-draggable {
    cursor: grab;
}

.timeline-draggable:active {
    cursor: grabbing;
} 